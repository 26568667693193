// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-memories-js": () => import("./../../../src/pages/memories.js" /* webpackChunkName: "component---src-pages-memories-js" */),
  "component---src-pages-projects-artcenter-library-js": () => import("./../../../src/pages/projects/artcenter-library.js" /* webpackChunkName: "component---src-pages-projects-artcenter-library-js" */),
  "component---src-pages-projects-fifa-js": () => import("./../../../src/pages/projects/fifa.js" /* webpackChunkName: "component---src-pages-projects-fifa-js" */),
  "component---src-pages-projects-fifa-museum-js": () => import("./../../../src/pages/projects/fifa-museum.js" /* webpackChunkName: "component---src-pages-projects-fifa-museum-js" */),
  "component---src-pages-projects-mola-js": () => import("./../../../src/pages/projects/mola.js" /* webpackChunkName: "component---src-pages-projects-mola-js" */),
  "component---src-pages-projects-pueblo-sonidero-js": () => import("./../../../src/pages/projects/pueblo-sonidero.js" /* webpackChunkName: "component---src-pages-projects-pueblo-sonidero-js" */),
  "component---src-pages-projects-pueblo-sonidero-mediatecture-js": () => import("./../../../src/pages/projects/pueblo-sonidero-mediatecture.js" /* webpackChunkName: "component---src-pages-projects-pueblo-sonidero-mediatecture-js" */),
  "component---src-pages-projects-rifla-js": () => import("./../../../src/pages/projects/rifla.js" /* webpackChunkName: "component---src-pages-projects-rifla-js" */),
  "component---src-pages-projects-suenos-js": () => import("./../../../src/pages/projects/sueños.js" /* webpackChunkName: "component---src-pages-projects-suenos-js" */),
  "component---src-pages-projects-typefaces-js": () => import("./../../../src/pages/projects/typefaces.js" /* webpackChunkName: "component---src-pages-projects-typefaces-js" */),
  "component---src-pages-typefaces-el-centro-js": () => import("./../../../src/pages/typefaces/el-centro.js" /* webpackChunkName: "component---src-pages-typefaces-el-centro-js" */),
  "component---src-pages-typefaces-mola-js": () => import("./../../../src/pages/typefaces/mola.js" /* webpackChunkName: "component---src-pages-typefaces-mola-js" */)
}

